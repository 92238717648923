<!-- 我的零钱首页 -->
<template>
  <div id="money">
    <div class="money">
      <div class="money_data box_shadow">
        <img class="icon_b" src="@image/me/money_b.png" alt="" />
        <div class="price">
          <i>￥</i>
          <b>{{ meMoney }}</b>
        </div>
        <p class="price_text">我的零钱（元）</p>
        <div class="changePrice_btn" @click="goApply" v-if="jiang_yc == 0">
          申请提现
        </div>
      </div>
    </div>
    <div class="money_list">
      <!-- 无数据的时候 -->

      <!-- <div class="title flex-container" v-if='listData.length != 0'>
            <div @click='sortShow=true'>{{sortText}}</div>
            <span class='flex1'>收入 ￥{{totalMomey}}</span>
          </div> -->

      <div class="no_data" v-if="listData.length == 0" style="margin-top:50px;">
        <img src="@image/me/money_nonedata.png" alt="" />
        <p>暂无明细</p>
      </div>

      <van-list
        error-text="请求失败，点击重新加载"
        v-model="loading"
        :finished="finished"
        finished-text="已无更多明细"
        @load="onLoad"
        v-if="listData.length != 0"
      >
        <div class="box_shadow Money_ul">
          <template v-for="item in listData">
            <div
              class="Money_li flex-container get"
              @click="goStatic(item.pdc_id)"
            >
              <img src="@image/me/money_icon_get.png" alt="" />
              <div class="info flex1">
                <p>
                  申请提现<span
                    :style="
                      item.pdc_payment_state == '0'
                        ? 'color:#FF884C;'
                        : 'color:#FF4747'
                    "
                    >{{
                      item.pdc_payment_state == "0" ? "(审核中)" : "(已到账)"
                    }}</span
                  >
                </p>
                <span>{{ item.pdc_sn }}</span>
              </div>
              <b>+{{ item.pdc_amount }}</b>
            </div>
          </template>
        </div>
      </van-list>
    </div>

    <!-- Shau筛选 -->
    <van-popup
      v-model="sortShow"
      position="bottom"
      round
      safe-area-inset-bottom
    >
      <div class="popul_tips">
        <div class="end-cancel flex-container">
          <p @click="sortShow = false">取消</p>
          <p @click="endSort()">确定</p>
        </div>
        <div class="content">
          <div class="content_ul  flex-container">
            <span
              @click="sortIndex = index"
              :class="{ act: index == sortIndex }"
              v-for="(item, index) in sortList"
              >{{ item }}</span
            >
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { List, Popup, Toast } from "vant";
import { post } from "@get/http";
export default {
  name: "",
  data: () => ({
    jiang_yc: localStorage["19jiang_yc"] ? localStorage["19jiang_yc"] : 0,
    list: [],
    loading: false,
    finished: false,
    sortShow: false, //筛选的罩住和内容
    sortList: ["全部", "本年", "本月"],
    sortIndex: 0,
    sortText: "全部",
    meMoney: 0.0,
    listData: [],
    page: 1,
    time_type: 1,
    totalMomey: 0,
  }),
  async created() {
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      loadingType: "spinner",
      overlay: true,
      duration: 0,
    });
    // /Member/index
    let { member_info } = await post("/Member/index");
    let data = member_info;
    this.meMoney = data.available_predeposit;
    this.onLoad();
    Toast.clear();
  },
  components: {
    vanList: List,
    vanPopup: Popup,
  },
  methods: {
    goApply() {
      this.$router.push({
        name: "Apply",
        query: {
          m: this.meMoney,
        },
      });
    },
    goStatic(id) {
      this.$router.push({
        name: "MoneyBillDetail",
        query: {
          order_id: id,
        },
      });
    },
    endSort() {
      this.sort();
      this.sortText = this.sortList[this.sortIndex];
      this.listData = [];
      this.page = 1;
      this.onLoad(this.sortIndex + 1);
    },
    sort() {
      this.sortShow = !this.sortShow;
    },
    async onLoad() {
      // 异步更新数据
      this.loading = true;

      // /Memberfund/predepositlog
      // /v2.Memberinviter/order
      let data = await post("/v2.Memberfund/pdcashlist", {
        data: {
          page: this.page,
          per_page: 20,
          time_type: this.time_type,
        },
      });

      this.pdcash_ok_total_amount = data.pdcash_ok_total_amount;
      this.page++;
      this.listData.push(...data.data);
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (data?.data < 20) {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
// @import '../../font.scss';

$bgc: #ededed;
#money {
  height: 100%;
  background: $bgc;
}
.money_list {
  background: $bgc;
}
.money {
  width: rem(750);
  height: rem(650);
  background: url("~@image/me/money_bg.png") no-repeat;
  background-size: rem(750) rem(422);
  position: relative;
  margin: 0 auto;
}
.money_data {
  height: rem(520);
  position: absolute;
  top: rem(110);
  left: 0;
  right: 0;
  margin: 0 auto;
  .icon_b {
    display: block;
    margin: 0 auto;
    width: rem(100);
    height: rem(100);
    padding: rem(78) 0 rem(46);
  }
  .price {
    position: relative;
    margin-right: rem(10);
    height: rem(70);
    text-align: center;
    i {
      font-weight: bold;
      font-size: rem(48);
      width: rem(28);
      height: rem(34);
    }
    b {
      font-size: rem(88);
    }
  }
}
.price_text {
  font-size: rem(28);
  color: #b2b2b2;
  text-align: center;
  margin-top: rem(32);
  margin-left: rem(10);
}
.changePrice_btn {
  width: rem(200);
  height: rem(58);
  border-radius: rem(29);
  border: 1px solid #ebebeb;
  color: #333333;
  text-align: center;
  line-height: rem(58);
  font-size: rem(28);

  margin: rem(52) auto 0;
}
.money_list {
  width: rem(750);
  margin: 0 auto;
  .no_data {
    img {
      width: rem(200);
      height: rem(200);
      display: block;
      margin: 0 auto;
    }
    p {
      height: rem(26);
      line-height: rem(26);
      text-align: center;
      font-weight: 500;
      color: #808080;
      margin-top: rem(32);
    }
  }
  .title {
    height: rem(100);
    align-items: center;
    width: rem(710);
    padding: 0 rem(32);
    margin: 0 auto;
    box-sizing: border-box;
    > div {
      font-size: rem(32);

      font-weight: bold;
      color: #333333;
      background: url("~@image/me/money_sort.png") no-repeat right center;
      background-size: rem(12) rem(9);
      padding-right: rem(12);
    }
    > span {
      text-align: right;
      font-size: rem(22);

      font-weight: 500;
      color: #b2b2b2;
    }
  }
}

.Money_ul {
  padding: rem(16) 0;
  box-sizing: border-box;
  .shop {
    b {
      color: #ff4747;
    }
  }
  .peopel {
    b {
      color: #ff884c;
    }
  }
  .get {
    b {
      color: #b2b2b2;
    }
  }
}
.Money_li {
  align-items: center;
  padding: rem(24) rem(32);
  box-sizing: border-box;
  img {
    width: rem(80);
    height: rem(80);
    display: block;
    margin-right: rem(24);
  }
  .info {
    p {
      font-size: rem(28);

      font-weight: 500;
      color: #333333;
    }
    span {
      font-size: rem(22);

      font-weight: 500;
      color: #b2b2b2;
    }
  }
  b {
    font-size: rem(36);

    font-weight: 400;
  }
}

.content {
  padding: 0 rem(32);
  box-sizing: border-box;
  height: rem(554);
  .content_ul {
    flex-wrap: wrap;
    .act {
      color: #ffffff;
      background: #ff4747;
    }
    span {
      display: block;
      width: rem(210);
      height: rem(100);
      background: #fff;
      border-radius: rem(4);
      line-height: rem(100);
      text-align: center;
      font-size: rem(28);

      font-weight: 500;
      color: #7f7f7f;
      margin-top: rem(68);
      &:nth-of-type(3n-1) {
        margin: rem(68) rem(28) 0;
      }
    }
  }
}
</style>
